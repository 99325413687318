import { type LoaderFunctionArgs } from '@remix-run/node'
import { Outlet } from '@remix-run/react'
// import Footer from '#app/components/footer.tsx'
import { redirectIfPageDoesNotExist } from '#app/utils/misc.server.ts'
// import { useParentData } from '#app/utils/misc.ts'

export async function loader({ request }: LoaderFunctionArgs) {
	const redirect = await redirectIfPageDoesNotExist(request)
	if (redirect) return redirect
	return {}
}

export default function Layout() {
	// const { navigation } = useParentData('/')

	return (
		<Outlet />
		// <div className="overflow-x-hidden">
		// 	<Outlet context={navigation.primary} />
		// 	<Footer links={navigation.footer} />
		// </div>
	)
}
